import { mapGetters } from 'vuex'

const recaptchaV3Verifier = {
  computed: {
    ...mapGetters(['showCaptchav2']),
    isRecaptchav3 () {
      const recaptchaV3Flag = Number(process.env.VUE_APP_RECAPTCHA_VERSION)
      return recaptchaV3Flag === 3 && !this.showCaptchav2
    }
  }
}

export default recaptchaV3Verifier
