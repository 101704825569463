<template>
  <div class="eu-mt_6 eu-pt_4">
    <div
      class="emd-container _highlight_footer _footer_spaced eu-mx_auto"
      style="max-width: 416px"
    >

      <div
        class="emd-container__content eu-pa_6 eu-display_flex eu-column"
        :class="{ 'resize-animation': animate }"
      >
        <router-link
          tag="a"
          class="eu-display_flex eu-justify_center eu-pb_6"
          href="javascript:void(0)"
          :to="{ name: 'signin' }"
        >
          <emd-pagarme-logo></emd-pagarme-logo>
        </router-link>

        <emd-alert
          v-if="hasError"
          variant="danger"
          @cancel="hideAlert"
          :title="''"
          autoclose
        >
          <template v-slot:content>
            <p
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ $t(error) }}
            </p>
          </template>
        </emd-alert>
        <form
          ref="handleForm"
          @submit.prevent="handleLogin"
          name="loginForm"
          novalidate="true"
          class="eu-ease-in"
        >
          <email-step
            :step="step"
            :email="email"
            @update:email="email = $event"
            :emailErrorState="emailErrorState"
            @update:emailErrorState="emailErrorState = $event"
            :emailIsValidState="emailIsValidState"
            @update:emailIsValidState="emailIsValidState = $event"
          >
          </email-step>

          <template v-if="step === 'password'">
            <password-step
              ref="passwordStep"
              :password="password"
              @update:password="password = $event"
              :passwordIsValid="passwordIsValid"
              @update:passwordIsValid="passwordIsValid = $event"
              :isLoading="isLoading"
              :passwordErrorState="passwordErrorState"
            />

            <recaptchav2-step
              ref="recaptchaStep"
              :recaptcha="recaptcha"
              @update:recaptcha="recaptcha = $event"
              :isLoading="isLoading"
              :showCaptchaFeedback="showCaptchaFeedback"
              @update:showCaptchaFeedback="showCaptchaFeedback = $event"
              @resetCaptcha="resetCaptcha"
            />
          </template>

          <div v-if="step === 'email'">
            <div class="emd-text _align_right eu-mt_4">
              <button
                data-cy="signin-submit-email"
                type="submit"
                class="emd-btn _color_primary _block_mobile eu-width_100"
                tabindex="0"
                :disabled="!emailIsValidState || isLoading"
              >
                <emd-loader
                  v-if="isLoading"
                  type="_subtle"
                ></emd-loader>
                <span v-else> {{ $t('LOGIN.BUTTON-TEXT') }}</span>
              </button>
            </div>
          </div>
          <div v-if="step === 'password'">
            <div class="emd-container__footer eu-no-padding eu-pb_3 eu-pt_2">
              <input
                type="button"
                class="emd-btn _color_primary _type_outline eu-grow_1 eu-mr_3_sm"
                @click="backToEmail"
                tabindex="-1"
                :value="$t('PASSWORD.BACK-BUTTON')"
              />
              <button
                data-cy="password-submit"
                type="submit"
                class="emd-btn _color_primary eu-grow_1 eu-ml_3_sm"
                tabindex="0"
                :disabled="!passwordIsValid || isLoading"
              >
                <emd-loader
                  type="_subtle"
                  v-if="isLoading"
                ></emd-loader>
                <span v-else>{{ $t('PASSWORD.BUTTON-TEXT') }}</span>
              </button>
            </div>
            <div
              class="eu-mt_5 eu-mb_2 eu-display_flex eu-justify_center"
              :class="{ 'popin-animation': animate }"
            >
              <a
                data-cy="reset-password-link"
                class="emd-text _size_section emd-input-link emd-link _color_primary"
                @click="sendToResetScreen"
                tabindex="-1"
              >
                {{ $t('PASSWORD.FORGOT-LINK') }}
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { EmdLoader, EmdPagarmeLogo, EmdAlert } from 'emerald-vue/src/components'
import EmailStep from '@/components/project/ui/EmailStep.vue'
import PasswordStep from '@/components/project/ui/PasswordStep.vue'
import Recaptchav2Step from '@/components/project/ui/Recaptchav2Step.vue'
import twofaRequired from '@/mixins/twofaRequired'
import recaptchaV3Verifier from '@/mixins/isRecaptchaV3.js'
import getRedirectApp from '@/utils/pilotRedirect'
import { InternalErrorHandler } from '@/support'

export default {
  name: 'Signin',
  components: {
    EmdAlert,
    EmdLoader,
    EmailStep,
    PasswordStep,
    EmdPagarmeLogo,
    Recaptchav2Step
  },
  mixins: [recaptchaV3Verifier, twofaRequired],
  computed: {
    totalUserFail () {
      return this.$store.getters.totalUserFail
    },
    hasError () {
      return (
        (this.emailErrorState || this.passwordErrorState) && this.errors.length
      )
    }
  },
  watch: {
    step () {
      if (this.step === 'email') {
        this.emailIsValidState = true
      }
      this.animate = true
      setTimeout(() => {
        this.animate = false
      }, 500)
    }
  },
  data () {
    return {
      step: 'email',
      email: '',
      password: '',
      recaptcha: null,
      showCaptchaFeedback: false,
      isLoading: false,
      emailIsValidState: false,
      passwordIsValid: false,
      emailErrorState: false,
      passwordErrorState: false,
      errors: [],
      params: null,
      redirectApp: '',
      redirectNext: '',
      animate: true,
      restrictUrl: [/@mundipagg.com$/, /@pagar.me$/, /@stone.com.br$/]
    }
  },
  methods: {
    handleLogin () {
      if (this.step === 'email') this.validateEmail()
      else this.createAuthorization()
    },
    backToEmail () {
      this.step = 'email'
      if (this.showCaptchav2) this.resetCaptcha()
    },
    // Email Step
    validateEmail () {
      this.errors = []
      this.isLoading = true
      if (this.restrictUser()) {
        this.step = 'password'
        this.isLoading = false
      } else {
        this.isLoading = false
        this.emailErrorState = true
        this.errors.push('LOGIN.RESTRICT-ERROR')
      }
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    restrictUser () {
      return this.restrictUrl.map(re => re.test(this.email)).includes(true)
    },
    // Login Step
    resetCaptcha () {
      this.showCaptchaFeedback = false
      this.recaptcha = null
      this.$refs.recaptchaStep.$refs.vueRecaptcha.reset()
    },
    async recaptchaV3Generator () {
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      this.recaptcha = await this.$recaptcha('login')
    },
    errorHandler (err) {
      const error = err.body
      const genericError = this.$t('PASSWORD.GENERIC-ERROR')
      const internalErrorHandler = new InternalErrorHandler(err, this.totalUserFail, genericError)

      this.errors = internalErrorHandler.handler()
      if (this.errors[0].needsTranslate) this.errors = [this.$t(this.errors[0].error)]

      if (error && Object.prototype.hasOwnProperty.call(error, 'cr')) {
        this.$store.dispatch('SET_SHOW_CAPTCHA', true)
      }

      const userFail = internalErrorHandler.isError('invalidPassword') || internalErrorHandler.isError('mfaNotAllowed')
      if (userFail) {
        this.$store.dispatch('SET_USER_FAIL', internalErrorHandler.totalError)
      } else if (internalErrorHandler.isError('captchaChallenge')) {
        this.$store.dispatch('SET_REQUIRED_CAPTCHA_V2', true)
      }
    },
    async createAuthorization () {
      this.sendEmailtoStore()
      this.passwordErrorState = false
      this.emailErrorState = false

      await this.$store.dispatch('REMOVE_SESSION_ID')

      const password = btoa(this.password)
      const body = { username: this.email, password: password }

      // Recaptcha engine
      if (this.showCaptchav2 && this.recaptcha === null) {
        this.showCaptchaFeedback = true
        return
      }

      this.isLoading = true

      if (this.isRecaptchav3) await this.recaptchaV3Generator()

      if (this.recaptcha !== null) {
        body.captcha_token = this.recaptcha
      }
      this.$store.dispatch('SET_LOGIN_DATA_TFA', body)
      const oneUser = !!this.redirectApp
      this.$store
        .dispatch('CREATE_AUTHORIZATION', { body, oneUser })
        .then(res => {
          const token = res.token
          this.passwordErrorState = false
          this.emailErrorState = false
          this.$store.dispatch('SET_USER_FAIL', 0)
          this.$store.dispatch('UPDATE_SIGN_LOADING', true)
          this.$store.dispatch('GO_TO_REDIRECT_URL')
          this.$store
            .dispatch('PROFILE_AUTHORIZATION')
            .then(r => {
              const tfauser = r.user_two_factor_authentication
              this.tfaCreateWithToken(tfauser, token)
              this.isLoading = false
            })
            .catch(() => {
              this.$store.dispatch('UPDATE_SIGN_LOADING', true)
              this.$store.dispatch('GO_TO_REDIRECT_URL')
              this.isLoading = false
            })
        })
        .catch(err => {
          this.isLoading = false
          const error = err.body
          const description = error?.error_description
          const tfaAllowedUser = this.componentIsEnabled('tfa', 'tfaLogin')
          if (this.showCaptchav2 && this.recaptcha !== null) this.resetCaptcha()
          this.recaptcha = null

          if (this.isTfaError(description) && tfaAllowedUser) {
            this.sendEmailtoStore()
            if(this.isTfaCodeRequired(description)) {
              return this.tfaRedirect(error)
            } else if (this.isTfaCreateRequired(description)){
              return this.tfaCreate(error)
            }
          } else {
            this.passwordErrorState = true
            this.emailErrorState = true
            return this.errorHandler(err)
          }
        })
    },
    hideAlert () {
      this.emailErrorState = false
      this.passwordErrorState = false
    },
    sendEmailtoStore () {
      this.$store.commit('SET_USER_EMAIL', this.email)
    },
    sendToResetScreen () {
      this.sendEmailtoStore()
      this.$router.push({ name: 'resetpassword' })
    }
  },
  beforeRouteEnter (to, _from, next) {
    const token = store.getters.token()
    const nextQueryParam = to.query?.next

    const deleteTokensAndNext = () => {
      next()
      store.dispatch('UPDATE_SIGN_LOADING', false)
      store.dispatch('DELETE_TOKENS')
    }

    const setStoreRedirectApp = (nextQueryParam) => {
        try {
          const nextURL = new URL(nextQueryParam)
          const app = getRedirectApp(nextURL, process.env.VUE_APP_PILOT_REDIRECT_NEW_URL)

          const redirectNext = {
            app: app,
            path: nextURL.pathname,
            search: ''
          }

          store.commit('SET_REDIRECT_NEXT', redirectNext)
        } catch (err) {
          return err
        }
    }

    if (token && !nextQueryParam) {
      store.dispatch('UPDATE_SIGN_LOADING', true)
      store.dispatch('GO_TO_REDIRECT_URL')
    } else if (token && nextQueryParam) {
      setStoreRedirectApp(nextQueryParam)
      store
        .dispatch('REDIRECT_MAKER')
        .then(() => store.dispatch('GO_TO_REDIRECT_URL'))
        .catch(() => deleteTokensAndNext())
    } else {
      deleteTokensAndNext()
    }
  },
  created () {
    if (this.$store.state.user.email) this.email = this.$store.state.user.email

    const hasParams = Object.keys(this.$route.query)
    if (hasParams.includes('email')) {
      this.params = { ...this.$route.query }
      this.email = this.params.email
      this.step = 'password'
      const next = this.params?.next
      if (next) {
        try {
          const nextURL = new URL(next)
          const redirectApp = getRedirectApp(nextURL, process.env.VUE_APP_PILOT_REDIRECT_NEW_URL)

          this.redirectNext = {
            app: redirectApp,
            path: redirectApp === 'pilot_local'? `:${nextURL.port}${nextURL.pathname}` : nextURL.pathname,
            search: ''
          }

          this.redirectApp = redirectApp

          this.$store.commit('SET_REDIRECT_NEXT', this.redirectNext)
        } catch (err) {
          return err
        }
      }
    }

    this.$store.commit('SET_REDIRECT_APP', this.redirectApp)

    setTimeout(() => { this.animate = false }, 500)
  }
}
</script>
